import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import SolidButton from "../componentes/buttons/SolidButton";
import useStateProducts from "../hooks/useStateProducts";
import serverComodin from "../config/serverComodin";
import { Link } from "react-router-dom";
import { imagenes } from "../config/configuraciones";
import { withRouter, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { detailsUser, updateUserProfile } from "../action/UserActions";

const API = serverComodin.baseURL + "/paginas/ofertas";

function Head({ id }) {

  //alert(userInfo.total_puntos);

  const ofertas = useStateProducts(API);
  const config_server = JSON.parse(localStorage.getItem("configServer"));
  // const userInfo = JSON.parse(localStorage.getItem("userInfo")); // Obtén la información del usuario desde localStorage

  let slider = 1;

  const config = {
    dots: true,
    infinite: true,
    slidesToShow: slider,
    slidesToScroll: 1,
    cssEase: "linear",
    arrows: false,
    autoplay: true,
    speed: 1500,
    autoplaySpeed: 4500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: slider,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          autoplay: true,
          speed: 1500,
          autoplaySpeed: 4500,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          autoplay: true,
          speed: 1500,
          autoplaySpeed: 4500,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          autoplay: true,
          speed: 1500,
          autoplaySpeed: 4500,
        },
      },
    ],
  };

  const handleCanjearClick = (uri) => {
    // Lógica o redirección al hacer clic en "Canjear"
    // history.push(uri);
    window.location.replace(uri);
  };
  const settings = config;

  const dispatch = useDispatch();

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

    const userDetails = useSelector((state) => state.userDetails);
    const { user } = userDetails;

  useEffect(() => {
    if (userInfo) {
      // dispatch({ type: USER_UPDATE_PROFILE_RESET });
      dispatch(detailsUser(userInfo.id_cliente));
    }
  }, []);

  return (
    <div id={id} className="imagen-home-promociones1">
      {/* <h1>Header</h1>
      <h2>Canjeá tus puntos por lo que te gusta</h2>

      <br />
      <br />
      
      
        <br/><br/>
       */}

      <div className="info_seccion" id="top-nav">
        <h1>TU PLATAFORMA MULTI RECOMPENSA</h1>
        {/* <div className="seleccion_fidelidad">
          <button className="btn_salas">SALAS</button>
          <button className="btn_virtual">VIRTUAL</button>
        </div> */}
        <div className="info">
          <div className="container">
            <div className="titulonombre">Nombre</div>
            {/* <div className="tituloedad">Edad</div> */}
            <div className="nombre">
              {userInfo
                ? `${userInfo.nombre} ${userInfo.apellido}`
                : "Nombre de usuario"}
            </div>

            {/* <div className="edad">38</div> */}
          </div>
          <div className="recompensas">
            <div className="puntos">
              <div className="titulopuntos">Puntos</div>
              <div className="logofidelidad">

                {(() => {
                  switch (user?.nivel) {
                    case "Plata":
                      return (
                        <img src={`${imagenes.urlImage}/fidelidad/plata.png`} alt="" />
                      );
                    case "Oro":
                      return (
                        <img src={`${imagenes.urlImage}/fidelidad/oro.png`} alt="" />
                      );
                    case "Platino":
                      return (
                        <img src={`${imagenes.urlImage}/fidelidad/platino.png`} alt="" />
                      );
                    case "Bronce":
                      return (
                        <img src={`${imagenes.urlImage}/fidelidad/bronce.png`} alt="" />
                      );
                    default:
                      return (
                        <img src={`${imagenes.urlImage}/fidelidad/oro.png`} alt="" />
                      );
                  }
                })()}
              </div>
              <div className="qypuntos">
                {userInfo ? `${user?.total_puntos ?? 0}` : "0"}
              </div>
            </div>
            <div className="regalos">
              <div className="tituloregalos">Premios</div>
              <div className="logoregalos">
                <img src={`${imagenes.urlImage}/regalo.png`} alt="" />
              </div>
              <div className="qyregalos">
                {user?.regalo_disponible
                  ? ` ${user.regalo_disponible}`
                  : "0"}
              </div>
            </div>
          </div>
          <div className="botones_info">
            <button
              onClick={() => handleCanjearClick("/canjea-tus-puntos")}
              className="btn_purple"
            >
              Canjear
            </button>
            {/* <button className="btn_purple">Canjear</button> */}
            <button
              onClick={() => handleCanjearClick("/premios")}

             className="btn_purple">Retirar</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Head);
// {
//   /* {!ofertas ? (
//         <div className="imagen-home-promociones1-oculto"></div>
//       ):( */
// }
// <Slider {...settings} aria-hidden="true">
//   {/* {ofertas.map((oferta) => { */}
//   {/* return ( */}
//   <div key="ofertas">
//     <div className="botones-img">
//       <div className="header-home">
//         <Link to="">
//           <img
//             src={`${imagenes.urlImage}/banner.png`}
//             className="header-img-cel"
//             alt="Imagen de jackpot comodin ¡Con Club Comodin Salas Salasganás o ganás! "
//           />
//         </Link>
//       </div>
//     </div>
//   </div>

//   {/* </div> */}
//   {/* );
//         })} */}
// </Slider>;
// {
//   /* )} */
// }
