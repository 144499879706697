import React from "react";
import { NavLink } from "react-router-dom";
import { FaHome, FaQrcode, FaShoppingCart } from "react-icons/fa";
import "../Styles/bottomNav.css"; // Importa el archivo CSS correspondiente

const BottomNav = () => {
  return (
    <div className="bottom-nav">
      <NavLink exact to="/" activeClassName="active">
        <FaHome className="icon" />
        <span>Inicio</span>
      </NavLink>
      <NavLink to="/scan-machine" activeClassName="active">
        <FaQrcode className="icon" />
        <span>Habilitar Máquina</span>
      </NavLink>
      <NavLink to="/canjea-tus-puntos" activeClassName="active">
        <FaShoppingCart className="icon" />
        <span>Compra</span>
      </NavLink>
    </div>
  );
};

export default BottomNav;
