import React from "react";

// import ReactDOM from "react-dom/client";
// import { createRoot } from "react-dom/client";
// import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// import ReactDOM from "react-dom/client";
// import "./index.css";

import { createRoot } from "react-dom/client";
const root = createRoot(document.getElementById("root"));
root.render(<App />);


// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   // <React.StrictMode>
//     <App />
//   // </React.StrictMode>
// );


// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(<App />);

// Si deseas medir el rendimiento de tu aplicación, puedes pasar una función
// para registrar resultados, por ejemplo: reportWebVitals(console.log)
// o enviar a un punto de análisis. Obtén más información: https://bit.ly/CRA-vitals
reportWebVitals();

