import React from "react";
import { imagenes } from "../config/configuraciones";

function Logos({ id }) {
  return (
    <div id={id} className="div-blanco text-center">
      <div className="title-cart2">
        <div className="title-cart">
          <h1>BENEFICIOS</h1>
          <h2>Disfruta de estos y más beneficios por nivel de fidelidad.</h2>
        </div>
        <img
          src={`${imagenes.urlImage}/medal.png`}
          className="juego-dados"
          alt="carrito"
        />
      </div>
      <img
        src={`${imagenes.urlImage}/grupo-5.png`}
        className="logos2"
        alt="carrito"
      />

      <div className="imagen-home-logos">
        <img
          src={`${imagenes.urlImage}/grupo-1.png`}
          className="logos"
          alt="carrito"
        />
        <img
          src={`${imagenes.urlImage}/grupo-2.png`}
          className="logos"
          alt="carrito"
        />
        <img
          src={`${imagenes.urlImage}/grupo-3.png`}
          className="logos"
          alt="carrito"
        />
        <img
          src={`${imagenes.urlImage}/grupo-4.png`}
          className="logos"
          alt="carrito"
        />
      </div>
    </div>
  );
}

export default Logos;
