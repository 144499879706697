import React from "react";
import Slider from "react-slick";
import SolidButton from "../componentes/buttons/SolidButton";
import useStateProducts from "../hooks/useStateProducts";
import serverComodin from "../config/serverComodin";
import { Link } from "react-router-dom";

const API = serverComodin.baseURL + "/paginas/ofertas";

function Promociones() {
  const ofertas = useStateProducts(API);
  const config_server = JSON.parse(localStorage.getItem('configServer'));
  let slider=1
  if(!ofertas){
    slider=1
  } else{
    slider = ofertas.length
    if(slider > 2){
      slider=2
    }
  }
  const config = {
    dots: true,
    infinite: true,
    slidesToShow:slider,
    slidesToScroll: 1,
    cssEase: "linear",
    arrows: false,
    autoplay: true,
    speed: 1500,
    autoplaySpeed: 4500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow:slider,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          autoplay: true,
          speed: 1500,
          autoplaySpeed: 4500,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow:1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          autoplay: true,
          speed: 1500,
          autoplaySpeed: 4500,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow:1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          autoplay: true,
          speed: 1500,
          autoplaySpeed: 4500,
        },
      },
    ],
  };

  const settings = config;

  return (
    <div className="imagen-home-promociones1">
      {!ofertas.length ? (
        <div className="imagen-home-promociones1-oculto"></div>
      ) : (
        <>
          <h1 style={{ color: "white" }}>Promociones</h1>
          <h2>Canjeá tus puntos por lo que te gusta</h2>

          <br />
          <br />

          <Slider {...settings} aria-hidden="true">
            {ofertas.map((oferta) => {
              return (
                <div key="ofertas">
                  <div className="botones-img">
                    <img
                      loading="lazy"
                      className="img-juego"
                      src={config_server.urlOfertas + oferta.img_comodin_promo}
                      alt="Productos y premios del Club comodín
                  productos para canjear, premios del club comodín. Tus respuestas tienen premio.
                  "
                    />
                  </div>

                  <div className="botones">
                    <Link to={oferta.link_comodin_promo}>
                      <SolidButton txt={oferta.btn_comodin_promo}></SolidButton>
                    </Link>
                  </div>
                </div>
              );
            })}
          </Slider>
        </>
      )}
      <br />
      <br />
    </div>
  );
}

export default Promociones;
