
const serverComodin = {
    baseURL: "https://proinso-juegos.quattropy.net/server/public/clubcomodin_salas",
 // baseURL: 'https://comodin.quattorinversiones.com/server/public/clubcomodin_salas',
//    baseURL: 'https://local.quattropy.com/gsc/server/public/clubcomodin_salas',
  //baseURL: 'https://local.quattropy.com/flexible.tools2/server_webv4/public/clubcomodin',
  //baseURL: 'https://local.quattropy.com/gsc/server/public/clubcomodin',
  urlProducto: "",
};

export default serverComodin;