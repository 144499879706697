import React, { useEffect, useState } from "react";
import { Link,useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import serverComodin from "../config/serverComodin";
import { signinApp } from "../action/UserActions";
import SolidButton from "../componentes/buttons/SolidButton";
import MetaTags from "react-meta-tags";
import Spinner from "../componentes/Spinner";
import { updateCart } from "../action/CartActions";
import {metaTags} from "../config/configuraciones"
import useLogin from "../hooks/useLogin";


const API = serverComodin.baseURL + "/product/st/listarTickets";

function LoginApp(props) {
  const [identification, setIdentification] = useState("");
  const [password, setPassword] = useState("");
  const loginApp = useLogin(API);
  const dispatch = useDispatch();

  
    

  

  const redirect = props.location.search
    ? props.location.search.split("=")[1]
    : "/";
  const userSignin = useSelector((state) => state.userSignin);

  const { loading, userInfo, error } = userSignin;
  const history = useHistory();
  useEffect(() => {
    if (userInfo) {
        // {LoginApp &&  window.location.replace(`/${LoginApp.data.productos}`)}
        dispatch(signinApp(identification, password,loginApp));
       
        console.log("userInfo",userInfo)
            return
        props.history.push("/payment");
        
           
      } else {
        props.history.push("/signin");
      }
    

    
    return () => {};
  }, [userInfo,props.history,redirect,dispatch,history]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(signinApp(identification, password,loginApp));
    return;
  };

  return (
    <React.Fragment>

      <div className="container-login">
        <form onSubmit={submitHandler}>
          <h1>Ingresar a mi cuenta</h1>

          <div>
            {loading && <p><Spinner/></p>}
            {error && <div className="error">{error}</div>}
          </div>
          <div className="datos-forms-1">
            <label className="label">Número de cédula</label>
            <input
              className="input"
              type="text"
              name="identification"
              id="identification"
              onChange={(e) => setIdentification(e.target.value)}
              required
            ></input>
            <label className="label">Contraseña</label>
            <input
              className="input"
              type="password"
              id="password"
              name="password"
              onChange={(e) => setPassword(e.target.value)}
              required
            ></input>

            <div className="centrar-botones">
              <SolidButton type="secondary" txt="Iniciar sesión"></SolidButton>

              <Link
                to={
                  redirect === "/"
                    ? "register"
                    : "register?redirect=" + redirect
                }
              >
                <SolidButton type="tertiary" txt="Registrate"></SolidButton>
              </Link>
            </div>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
}
export default LoginApp;
