import { priceNivel } from "../componentes/Util";
import Swal from 'sweetalert2'; // Importa SweetAlert2
// import toastr from 'toastr'; // Importa Toastr.js

import {
    ADD_TO_CART,
    REMOVE_FROM_CART,
    DECREMENT_TO_CART,
  } from "../constants/CartType";
  
  export const addToCart = (product, nivel) => (dispatch, getState) => {
    // console.log(getState());
    const cartItems = getState().cart.cartItems.slice();

    console.log("cartItems", product, nivel);
    let alreadyExists = false;

    cartItems.forEach((x) => {
      if (x.id_producto === product.id_producto) {
        alreadyExists = true;
        x.count++;
      }
      x.precio_compra = priceNivel(
        x.precio_bronce_producto,
        x.precio_plata_producto,
        x.precio_oro_producto,
        x.precio_platino_producto,
        nivel
      );
    });
    if (!alreadyExists) {
      cartItems.push({
        ...product,
        precio_compra: priceNivel(
          product.precio_bronce_producto,
          product.precio_plata_producto,
          product.precio_oro_producto,
          product.precio_platino_producto,
          nivel
        ),
        count: 1,
      });
    }
    console.log("cartItems2", product, nivel);
    dispatch({
      type: ADD_TO_CART,
      payload: { cartItems },
    });
    localStorage.setItem("cartItems", JSON.stringify(cartItems));

    //  Swal(
    //    "¡Producto Agregado!",
    //    "El producto se ha agregado al carrito.",
    //    "success"
    //  );


    // Mostrar mensaje de alerta
  Swal.fire({
    icon: "success",
    title: "¡Producto Agregado!",
    text: "El producto se ha agregado al carrito.",
    position: "top-end",
    showConfirmButton: false,
    timer: 2000,
  });


  };
  
  export const removeFromCart = (product) => (dispatch, getState) => {
    const cartItems = getState()
      .cart.cartItems.slice()
      .filter((x) => x.id_producto !== product.id_producto);
    dispatch({ type: REMOVE_FROM_CART, payload: { cartItems } });
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
     Swal.fire({
       icon: "warning",
       title: "¡Producto Eliminado!",
       text: "El producto se ha eliminado del carrito.",
       position: "top-end",
       showConfirmButton: false,
       timer: 2000,
     });

  };
  // export const saveFacturacion = (data) => (dispatch) => {
  //   dispatch({ type: CART_SAVE_FACTURACION, payload: data });
  //   localStorage.setItem("HomeCompra", JSON.stringify(data));
  // };
  // export const savePayment = (data) => (dispatch) => {
  //   dispatch({ type: CART_SAVE_PAYMENT, payload: data });
  // };


  export const decrementToCart = (product) => (dispatch, getState) => {
    console.log("cart.2",getState())
    const cartItems = getState().cart.cartItems.slice();
  
    let alreadyExists = false;
  
    cartItems.forEach((x) => {
      if (x.id_producto === product.id_producto) {
        alreadyExists = true;
        x.count--;
      }
    });
    if (!alreadyExists) {
      cartItems.push({ ...product, count: -1 });
    }
    dispatch({
      type: DECREMENT_TO_CART,
      payload: { cartItems },
    });
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
   

  };
  

  export const updateCart = (nivel) => (dispatch, getState) => {
    
    const cartItems = getState().cart.cartItems.slice();

    cartItems.forEach((x) => {
      x.precio_compra = priceNivel(
        x.precio_bronce_producto,
        x.precio_plata_producto,
        x.precio_oro_producto,
        x.precio_platino_producto,
        nivel
      );
    });
    console.log("update",cartItems)
    dispatch({
      type: ADD_TO_CART,
      payload: { cartItems },
    });
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
    window.location.replace("/carrito");
  };