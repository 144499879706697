import React from "react";
import '../App.css';
import { Link } from "react-router-dom";

class Sidebar2 extends React.Component {
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  }

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.closeSidebar();
    }
  }

  closeSidebar = () => {
    document.querySelector(".sidebar").classList.remove("open");
  }

  scrollToSection = (sectionId) => {
    this.closeSidebar();
    const section = document.getElementById(sectionId);
    if (section) {
      const topPosition = section.offsetTop - 55;
      window.scrollTo({
        top: topPosition,
        behavior: "smooth",
      });
    }
  };

  logout = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  render() {
    return (
      <div className="lista" ref={this.setWrapperRef}>
        <Link
          onClick={() => this.scrollToSection("inicio")}
          className="lista-sidebar"
          to="/#inicio"
        >
          Inicio
        </Link>
        {localStorage.userInfo ? (
          <>
            <Link
              onClick={this.closeSidebar}
              to="/mi-cuenta"
              id="Profile"
              className="lista-sidebar"
            >
              Mi Perfil
            </Link>
            <Link
              onClick={this.closeSidebar}
              to="/pedidos"
              id="Profile"
              className="lista-sidebar"
            >
              Mis Pedidos
            </Link>
          </>
        ) : null}
        <Link
          onClick={this.closeSidebar}
          to="/canjea-tus-puntos"
          id="canjear"
          className="lista-sidebar"
        >
          Productos
        </Link>
        <Link
          onClick={() => this.scrollToSection("beneficios")}
          className="lista-sidebar"
          to="/#beneficios"
        >
          Beneficios
        </Link>
        <Link
          onClick={() => this.scrollToSection("preguntasFrecuentes")}
          className="lista-sidebar"
          to="/#preguntasFrecuentes"
        >
          Preguntas frecuentes
        </Link>
        <Link
          onClick={() => this.scrollToSection("juegoResponsable")}
          className="lista-sidebar"
          to="/#juegoResponsable"
        >
          Juego responsable
        </Link>
        <Link
          onClick={() => this.scrollToSection("contactos")}
          className="lista-sidebar"
          to="/#contactos"
        >
          Contacto
        </Link>
        {localStorage.userInfo ? (
          <button onClick={this.logout} className="cerrar-sesion-btn">
            Cerrar Sesión
          </button>
        ) : (
          <Link
            onClick={this.closeSidebar}
            to="/signin"
            id="Login"
            className="lista-sidebar"
          >
            Login
          </Link>
        )}
      </div>
    );
  }
}

export default Sidebar2;