import React from "react";


const MapGoogle = () => {
    const config_server = JSON.parse(localStorage.getItem("configServer"));
    return<>
              <iframe
              title = "mapa google"
                src={`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3605.7792621750555!2d${config_server.mapLongitud}!3d${config_server.mapLatitud}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMjXCsDIwJzQyLjciUyA1N8KwMzAnNDAuOSJX!5e0!3m2!1ses-419!2spy!4v1551350967614`}
                
                // src={`${config_server.mapUbicacion}`}
                frameBorder="0"
                style={{
                  border: "0",
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#f8f7f7",
                }}
                allowFullScreen
              ></iframe>
  </>
    
}
export default MapGoogle;