import Cookie from 'js-cookie';
import {
  USER_SIGNIN_REQUEST, USER_SIGNIN_SUCCESS,
  USER_SIGNIN_FAIL, USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS, USER_REGISTER_FAIL,USER_LOGOUT,  USER_UPDATE_PROFILE_SUCCESS,
  USER_UPDATE_PROFILE_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL,
  USER_UPDATE_PROFILE_REQUEST,
  USER_RECUPERAR_SUCCESS,
  USER_RECUPERAR_REQUEST,
  USER_RECUPERAR_FAIL,
} from "../constants/UserType";

import clienteAxios from '../config/axios';
import swal from "sweetalert";
import { CART_EMPY } from "../constants/CartType";

function excepciones2 (ns){
  this.message = ns
}

const register = (formData) => async (dispatch) => {
  dispatch({ type: USER_REGISTER_REQUEST, payload: formData });

  try {
    const form = new FormData();

    // Añadir todos los campos de texto
    Object.keys(formData).forEach(key => {
      if (key !== 'idFront' && key !== 'idBack') {
        form.append(key, formData[key]);
      }
    });

    // Añadir las imágenes
    if (formData.idFront) {
      form.append('idFront', formData.idFront);
    }
    if (formData.idBack) {
      form.append('idBack', formData.idBack);
    }

    const { data } = await clienteAxios.post("user/st/addUsuario", form, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    if (data.status !== "success") {
      throw new Error(data.message);
    }

    if (data.status === "success") {
      swal({
        title: "¡Te registraste con éxito!",
        text: "Inicia sesión",
        icon: "success",
        button: "Iniciar sesión",
        closeOnClickOutside: false,
      }).then((willRedirect) => {
        if (willRedirect) {
          window.location.replace("/signin");
        }
      });
      dispatch({ type: USER_REGISTER_SUCCESS, payload: data.data });
    }
  } catch (error) {
    swal({
      title: "¡Ups!",
      text: error.message,
      icon: "error",
      button: "Entendido",
    });
    console.error(error.message);
    dispatch({ type: USER_REGISTER_FAIL, payload: error.message });
  }
};
function excepciones (ns){
  this.message = ns
}
const signin = (identification, password) => async (dispatch) => {
  dispatch({
    type: USER_SIGNIN_REQUEST,
    payload: { identification: identification, password: password },
  });

  try {
    const { data } = await clienteAxios.post("user/st/login", {
      identification: identification,
      password: password,
    });
    if (data.status !== "success") {
      throw new excepciones(data.message);
    }

    dispatch({ type: USER_SIGNIN_SUCCESS, payload: data.data });

    localStorage.setItem("userInfo", JSON.stringify(data.data));
  } catch (error) {
    console.log(error.message);
    dispatch({ type: USER_SIGNIN_FAIL, payload: error.message });
  }
};
function excepciones4 (ns){
  this.message = ns
}
const signinApp = (identification, password,loginApp) => async (dispatch) => {
  dispatch({
    type: USER_SIGNIN_REQUEST,
    payload: { identification: identification, password: password,loginApp:loginApp },
  });

  try {
    const { data } = await clienteAxios.post("user/st/login", {
      identification: identification,
      password: password,
      loginApp:loginApp
    });
    if (data.status !== "success") {
      throw new excepciones4(data.message);
    }

    dispatch({ type: USER_SIGNIN_SUCCESS, payload: data.data });

    localStorage.setItem("userInfo", JSON.stringify(data.data));
  } catch (error) {
    console.log(error.message);
    dispatch({ type: USER_SIGNIN_FAIL, payload: error.message });
  }
};


function excepciones3(ns) {
  this.message = ns;
}
const recuperar = (email) => async (dispatch) => {
  dispatch({ type: USER_RECUPERAR_REQUEST, payload: { email: email } });

  try {
    const { data } = await clienteAxios.post("user/st/recuperar", { email: email });
    if (data.status !== "success") {
      throw new excepciones3(data.message);
    }
    dispatch({ type: USER_RECUPERAR_SUCCESS, payload: data.data });

    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    console.log(error.message);
    dispatch({ type: USER_RECUPERAR_FAIL, payload: error.message });
  }
};


const logout = () => (dispatch) => {

  dispatch({ type: USER_LOGOUT });
  dispatch({ type: CART_EMPY });
  localStorage.removeItem("userInfo");
  localStorage.removeItem("cartItems");
  localStorage.removeItem("facturacion");
  window.location.replace('/');
};




const detailsUser = (userId) => async (dispatch, getState) => {
  dispatch({ type: USER_DETAILS_REQUEST, payload: userId });
  const {
    userSignin: { userInfo },
  } = getState();
  try {
    const { data } = await clienteAxios.get(`user/fidelidad/${userId}` ,{
      headers: { Token: `Bearer ${userInfo.token}` }
    });
    dispatch({ type: USER_DETAILS_SUCCESS, payload: data.data });

  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    localStorage.removeItem("userInfo");
    dispatch({ type: USER_LOGOUT });
    dispatch({ type: USER_DETAILS_FAIL, payload: message });
    
  }
};


const updateUserProfile = (user,id_usu,nombre,apellido,tipo_documento, ruc_cliente,fecha_nac,nickname,sexo,email,telefono,direccion,
  actividad_economica) => async (dispatch, getState) => {
  dispatch({ type: USER_UPDATE_PROFILE_REQUEST, payload: user });
  const {
    userSignin: { userInfo },
  } = getState();
  //console.log(user)
  try {
    const { data } = await clienteAxios.post(`user/editUsuario`,{ id_usuario:user.id_usu,nombre:user.nombre,apellido:user.apellido,tipo_documento:user.tipo_documento,ruc_cliente:user.ruc_cliente,fecha_nac:user.fecha_nac,nickname:user.nickname,sexo:user.sexo,email:user.email, telefono:user.telefono, direccion:user.direccion,actividad_economica:user.actividad_economica } ,{
      headers: { Token: `Bearer ${userInfo.token}` },
    });
   
    if (data.status === "success") {
      dispatch({ type: USER_UPDATE_PROFILE_SUCCESS, payload: data.data });
      dispatch({ type: USER_SIGNIN_SUCCESS, payload: data.data });
      localStorage.removeItem("userInfo");
      dispatch({ type: USER_LOGOUT });
      Cookie.set('userInfo', JSON.stringify(data));
      swal({
        title: "Tus cambios se realizaron con éxito",
        text: "Volvé a iniciar sesión",
        dangerMode: true,
        button: "ok",
        icon: "success",
        closeModal: true,
      }).then((willDelete) => {
        if (willDelete) {

        }
      });
    } else{
      swal({
        title: "¡Ups!",
        text: data.message,
        icon: "",
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
        }
      });
    }
   
   
  } catch (error) {
    swal({
      title: "¡Ups!",
      text: error.message,
      icon: "",
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
      }
    });
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
        localStorage.removeItem("userInfo");
        dispatch({ type: USER_LOGOUT });
    dispatch({ type: USER_UPDATE_PROFILE_FAIL, payload: message });
  }
};



const updateUserContrasena = ( user,new_password,old_password ) => async (dispatch, getState) => {
  dispatch({ type: USER_UPDATE_PROFILE_REQUEST, payload: user });
  const {
    userSignin: { userInfo },
  } = getState();
  //console.log(user)
  try {
    const { data } = await clienteAxios.post(`user/cambiarContrasena`,{ new_password:user.new_password,old_password:user.old_password } ,{
      headers: { Token: `Bearer ${userInfo.token}` },
    });
   

    if (data.status === "success") {
      dispatch({ type: USER_UPDATE_PROFILE_SUCCESS, payload: data.data });
      dispatch({ type: USER_SIGNIN_SUCCESS, payload: data.data });
      localStorage.removeItem("userInfo");
      dispatch({ type: USER_LOGOUT });
      Cookie.set('userInfo', JSON.stringify(data));
      swal({
        title: "Tus cambios se realizaron con éxito",
        text: "Volvé a iniciar sesión",
        icon: "success",
        dangerMode: true,
        button: "ok",
        closeModal: true,
      }).then((willDelete) => {
        if (willDelete) {

        }
      });

    } else{
      swal({
        title: "¡Ups!",
        text: data.message,
        icon: "",
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
        }
      });
    }
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
        swal({
          title: "¡Ups!",
          text: error.message,
          icon: "",
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
          }
        });
        localStorage.removeItem("userInfo");
        dispatch({ type: USER_LOGOUT });
    dispatch({ type: USER_UPDATE_PROFILE_FAIL, payload: message });
  }
};


export { signin,logout,detailsUser,updateUserProfile,register,recuperar, updateUserContrasena,signinApp};