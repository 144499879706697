import React from "react";
import SolidButton from "../componentes/buttons/SolidButton";
import Zoom from 'react-reveal/Zoom';
import {imagenes} from '../config/configuraciones'


function JuegoResp({ id } ) {
  const scrollToSection = () => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div id={id} className="imagen-home-promociones juegores_seccion">
      <h1>JUEGO RESPONSABLE</h1>
      <h2>
        El juego compulsivo puede dañar la salud. Nos interesa que te diviertas
        y que lo hagas sanamente.
      </h2>
      <div className="centrar">
        <div className="imagen-home-juego">
          <div className="centrar">
            <img
              loading="lazy"
              src={`${imagenes.urlImage}/list.png`}
              className="juego-list"
              alt="juego responsable"
            />
          </div>
          <br />
          <h1 className="">TEST DE LUDOPATÍA</h1>
          <br />
          <h2>100% CONFIDENCIAL</h2>
          <br />
          <div className="centrar">
            <Zoom>
              <a href="https://juegoresponsable.com.py">
                <SolidButton txt="Ver más"></SolidButton>
              </a>
            </Zoom>
          </div>
        </div>
      </div>
      <br />
      <div className="centrar">
        <img
          loading="lazy"
          src={`${imagenes.urlImage}/grupo-4175.png`}
          className="juego-list"
          alt="juego responsable"
        />
      </div>
    </div>
  );
}

export default JuegoResp;
