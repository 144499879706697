import React, { useState, useCallback, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Draggable from "react-draggable";
import "./FloatingButton.css";
import clienteAxios from "../config/axios";
import { useSelector } from "react-redux";

const FloatingButton = () => {
  const history = useHistory();
  const location = useLocation();
  const [isDragging, setIsDragging] = useState(false);
  const [dragStartTime, setDragStartTime] = useState(0);
  const [hasRulette, setHasRulette] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  const checkRulette = useCallback(async () => {
    if (userInfo && userInfo.id_cliente) {
      setIsLoading(true);
      try {
        const response = await clienteAxios.get(`/user/ruletas/${userInfo?.id_cliente}`, { headers: { Token: `Bearer ${userInfo?.token}` } });
        setHasRulette(response.data.status === 'success' && response.data.data.length > 0);
      } catch (error) {
        console.error('Error fetching rulette data:', error);
        setHasRulette(false);
      } finally {
        setIsLoading(false);
      }
    } else {
      setHasRulette(false);
      setIsLoading(false);
    }
  }, [userInfo]);

  useEffect(() => {
    checkRulette();

    // Configurar el intervalo para verificar cada 5 minutos (300000 ms)
    const interval = setInterval(checkRulette, 300000);

    // Limpiar el intervalo cuando el componente se desmonte
    return () => clearInterval(interval);
  }, [checkRulette, location]);

  const handleStart = useCallback(() => {
    setIsDragging(true);
    setDragStartTime(Date.now());
  }, []);

  const handleStop = useCallback(() => {
    const dragDuration = Date.now() - dragStartTime;
    if (dragDuration < 200) {
      history.push("/rulette");
    }
    setIsDragging(false);
  }, [history, dragStartTime]);

  if (isLoading || !hasRulette || location.pathname === "/rulette" || location.pathname === "/register" || location.pathname === "/signin" || location.pathname === "/ruleta-de-la-fortuna") {
    return null;
  }

  return (
    <Draggable onStart={handleStart} onStop={handleStop}>
      <div className="floating-button-container">
        <div className="floating-button">
          <svg
            className="roulette-wheel"
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="50" cy="50" r="45" fill="#333" />
            <circle cx="50" cy="50" r="40" fill="#fff" />
            {[...Array(18)].map((_, i) => (
              <path
                key={i}
                d={`M50,50 L${50 + 40 * Math.cos((i * 20 * Math.PI) / 180)},${50 + 40 * Math.sin((i * 20 * Math.PI) / 180)
                  } A40,40 0 0,1 ${50 + 40 * Math.cos(((i + 1) * 20 * Math.PI) / 180)
                  },${50 + 40 * Math.sin(((i + 1) * 20 * Math.PI) / 180)} Z`}
                fill={
                  ["#e74c3c", "#3498db", "#2ecc71", "#f1c40f", "#9b59b6"][i % 5]
                }
              />
            ))}
            <circle cx="50" cy="50" r="5" fill="#333" />
          </svg>
          <span>¡Juega y Gana!</span>
        </div>
      </div>
    </Draggable>
  );
};

export default FloatingButton;