import React from "react";
import "../Styles/PreguntasFrecuentes.css";
import Preguntas from "./Preguntas";

const PreguntasFrecuentes = ({ id }) => {
  return (
    <div id={id} className="preguntas">
      <Preguntas
        title="¿Qué es Che Comodin?"
        text="Che Comodín es una plataforma que te ofrece la chance de juntar puntos de fidelidad que después podes usar para obtener un montón de beneficios y hasta participar en sorteos!"
      />
      <Preguntas
        title="¿Cómo me registro?"
        text="¡Regístrate fácilmente! Solo tenes que acercarte a una de nuestras cajas en los salones autorizados."
      />
      <Preguntas
        title="¿Qué requisitos necesito para registrarme?"
        text="¡Es sencillo registrarse! Solo necesitas ser mayor de edad, facilitar un número de contacto y tu cédula de identidad para verificar tu cuenta. ¡Listo para empezar!"
      />

      <Preguntas
        title="¿Cómo Acumulo puntos?"
        text="Cada vez que cambias dinero por fichas y/o créditos en nuestros salones autorizados sumas puntos automáticamente! Después, podes canjearlos por productos geniales en nuestra página web o participar en los sorteos disponibles!"
      />

      <Preguntas
        title="¿Dónde retiro mis premios?"
        text="Podes recoger tus premios en nuestros Salones habilitados!"
      />
      <Preguntas
        title="¿Los puntos tienen caducidad?"
        text="Los puntos expiran después de 3 meses, ¡así que no te olvides de usarlos!"
      />

      {/* <Preguntas
        title="¿Qué hago con mis puntos?"
        text="Podés retirar productos, participar en sorteos, ganar regalos y más."
      />
      <Preguntas
        title="¿Cómo descargo
        la aplicación?"
        text="Podés descargar la aplicación Che Comodín en Google Playstore y Apple Store."
      />
      <Preguntas
        title="¿Cómo puedo sumar puntos?"
        text="Con cada jugada en las salas Che Comodin ya estás sumando puntos."
      />
      <Preguntas
        title="¿Dónde retiro mis regalos?"
        text="Podés retirar tus regalos y productos en todas nuestras salas."
      />
      <Preguntas
        title="¿Cómo se paga?"
        text="Podés cobrar tus premios y comprar créditos en efectivo en caja y también con Tigo Money o Billetera Personal."
      /> */}
      <Preguntas
        title="¿Cuántos niveles hay?"
        text="Hay cuatro niveles de fidelidad: Bronce, Plata, Oro y Platino. ¡Hay distintos premios en todos los niveles!"
      />
    </div>
  );
};

export default PreguntasFrecuentes;
