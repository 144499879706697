
import styled from 'styled-components'

const StyledSolidButton = styled.button `
  font-size: calc(16px + 6 * ((10vw - 320px) / 680));
  background-color: #C0EB3A;
  border: rgb(196, 184, 184);
  border-radius: 1rem;
  color: #436426;
  width: 13rem;
  height: 4.5rem;
  margin: 2rem;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  &:hover {
    cursor: pointer;
    opacity:0.75;
  }

  ${props => props.type === 'primary'&&`

  background-color:  #FCC02C;
  color: #A8441D;

  `}
  ${props => props.type === 'secondary'&&`

  background-color: #C0EB3A;
  width: 32rem;
  height: 4rem;
  @media (min-width:860px) {
    background-color: #C0EB3A;
    width: 40rem;
    height: 4rem;
  }
  `}

  ${props => props.type === 'tertiary'&&`

  background-color: #FCC02C;
  width: 32rem;
  height: 4rem;
  color: #A8441D;
  margin-top:0;
  @media (min-width:860px) {
 
    width: 40rem;
    height: 4rem;
  }
  `}
  ${props => props.type === 'red-agregar-c'&&`

  font-size: calc(16px + 6 * ((10vw - 320px) / 680));
  background-color: #C0EB3A;
  border: rgb(196, 184, 184);
  border-radius: 1rem;
  color: #436426;
  width: 13rem;
  height: 3.5rem;
  margin: 2rem;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  &:hover {
    cursor: pointer;
    opacity:0.75;
  }
  `}
  ${props => props.type === 'amarillo-compra'&&`

  font-size: calc(16px + 6 * ((10vw - 320px) / 680));
  background-color: #FCC02C;
  border: rgb(196, 184, 184);
  border-radius: 1rem;
  color: #A8441D;
  width: 13rem;
  height: 3.5rem;
  margin: 2rem;
  margin-top: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  &:hover {
    cursor: pointer;
    opacity:0.75;
  }
  `}
  ${props => props.type === 'amarillo-compra-productos-p'&&`

  font-size: calc(16px + 6 * ((10vw - 320px) / 680));
  background-color: #FCC02C;
  border: rgb(196, 184, 184);
  border-radius: 1rem;
  color: #A8441D;
  width: 15rem;
  height: 3.5rem;
  margin: 2rem;
  margin-top: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  &:hover {
    cursor: pointer;
    opacity:0.75;
  }
  `}
  ${props => props.type === 'amarillo-compra-productos-p-chico'&&`

  font-size: calc(16px + 6 * ((10vw - 320px) / 680));
  background-color: #FCC02C;
  border: rgb(196, 184, 184);
  border-radius: 1rem;
  color: #A8441D;
  width: 10rem;
  height: 3.5rem;
  margin-top: 2rem;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  &:hover {
    cursor: pointer;
    opacity:0.75;
  }
  `}

  ${props => props.type === 'agregar'&&`
  font-size: calc(16px + 6 * ((10vw - 320px) / 680));
  background-color: #C0EB3A;
  border: rgb(196, 184, 184);
  border-radius: 1rem;
  color: #436426;
  width: 13rem;
  height: 3.5rem;
  margin: 2rem;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  &:hover {
    cursor: pointer;
    opacity:0.75;
  }
  @media (min-width:700px) {
    font-size: calc(16px + 6 * ((5vw - 320px) / 680));
  }
  `}
  ${props => props.type === 'agregar-productos-p'&&`
  font-size: calc(16px + 6 * ((5vw - 320px) / 680));
  background-color: #C0EB3A;
  border: rgb(196, 184, 184);
  border-radius: 1rem;
  color: #436426;
  width: 15rem;
  height: 4rem;
  margin: 2rem;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  &:hover {
    cursor: pointer;
    opacity:0.75;
  }
  @media (min-width:700px) {
    font-size: calc(16px + 6 * ((5vw - 320px) / 680));
  }
  `}
  ${props => props.type === 'agregar-productos-p-chico'&&`
  font-size: calc(16px + 6 * ((5vw - 320px) / 680));
  background-color: #C0EB3A;
  border: rgb(196, 184, 184);
  border-radius: 1rem;
  color: #436426;
  width: 10rem;
  height: 4rem;
  margin-top: 2rem;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  &:hover {
    cursor: pointer;
    opacity:0.75;
  }
  @media (min-width:700px) {
    font-size: calc(16px + 6 * ((5vw - 320px) / 680));
  }
  `}
  

`;
export default StyledSolidButton;