import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import SolidButton from "../componentes/buttons/SolidButton";
import { contacto } from "../action/ProjectActions";
import MetaTags from "react-meta-tags";
import MapGoogle from "../maps/MapGoogle";
import Spinner from "../componentes/Spinner";
import { imagenes, metaTags } from "../config/configuraciones";

function Contacto({ id }) {
  const [name, setName] = useState("");
  const [last_name, setLast_name] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const config_server = JSON.parse(localStorage.getItem("configServer"));

  const userRegisterRepresentante = useSelector(
    (state) => state.userRegisterRepresentante
  );
  const { loading, userInfo, error } = userRegisterRepresentante;
  const dispatch = useDispatch();

  //   const redirect = props.location.search
  //     ? props.location.search.split("=")[1]
  //     : "/";
  //   useEffect(() => {
  //     if (userInfo) {
  //       props.history.push(redirect);
  //     }
  //     return () => {
  //       //
  //     };
  //   }, [userInfo,redirect,props.history]);

  const submitHandler = (e) => {
    e.preventDefault();

    dispatch(contacto(name, last_name, email, message));
  };

  return (
    <section id={id}>
      <br />
      <div className="title-cart2">
        <img
          src={`${imagenes.urlImage}/contacto.png`}
          className="juego-dados"
          alt="carrito"
        />

        <div className="title-cart">
          <h1 className="color-white1">CONTACTO</h1>
        </div>
      </div>
      <div className="contacto-home2">
        <div className="container-login-form">
          <form onSubmit={submitHandler}>
            <main>
              <div className="datos-forms-c">
                {loading && (
                  <div>
                    <Spinner />
                  </div>
                )}
                {error && <div className="error">{error}</div>}
                <br />
                <br />
                <input
                  placeholder="Nombre"
                  className="input"
                  type="text"
                  name="name"
                  id="name"
                  onChange={(e) => setName(e.target.value)}
                  required
                ></input>
                <input
                  placeholder="Apellido"
                  className="input"
                  type="text"
                  id="last_name"
                  name="last_name"
                  onChange={(e) => setLast_name(e.target.value)}
                ></input>

                <input
                  placeholder="Correo electrónico"
                  className="input"
                  type="email"
                  name="email"
                  id="email"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                ></input>
                <main>
                  <textarea
                    className="comentarios"
                    placeholder="Mensaje"
                    rows="8"
                    cols="35"
                    type="text"
                    id="message"
                    name="message"
                    onChange={(e) => setMessage(e.target.value)}
                    required
                  ></textarea>
                </main>
              </div>
              <div className="centrar-botones-r">
                <SolidButton type="tertiary" txt="ENVIAR"></SolidButton>
              </div>
              <br />
              <br />
            </main>
          </form>
          <div className="footer-redes">
            <a href=" https://www.facebook.com/clubcomodinpy">
              <img
                src="../../comodin2/facebook-15.png"
                className="redes-sociales-f"
                alt="facebook"
              />
            </a>
            <a href=" https://www.instagram.com/clubcomodinpy">
              <img
                src="../../comodin2/instagram-15.png"
                className="redes-sociales-f"
                alt="instagram"
              />
            </a>

            <div className="whatsapp-c">
              <a href="https://wa.link/p5149z">
                <img
                  src="../../comodin2/whatsapp-15.png"
                  className="redes-sociales-f-w"
                  alt="whatsapp"
                />
              </a>
            </div>
          </div>
          <br />
          <br />
        </div>
      </div>
    </section>
  );
}
export default Contacto;
