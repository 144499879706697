import React, { Component } from "react";

import "../App.css";
import Zoom from "react-reveal/Zoom";
import { imagenes } from "../config/configuraciones";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="footer">
      <br />
      {/* <div className="footer-logo">
      <Zoom bottom>
        <Link to="/">
        <img src="../../checomodin/logo1-2.png" className="logo-footer" alt="Logotipo Club Comodín. El club de apuestas, máquinas slot tragamonedas y juegos de azar que más paga." />
        </Link>
        </Zoom>
      </div> */}

      <div className="footer-derechos">
        <p>© 2022. Quattro Inversiones SA.</p>
      </div>
    </div>
  );
}

export default Footer;
