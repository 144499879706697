import React from "react";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";
import { imagenes } from "../config/configuraciones";
import PreguntasFrecuentes from "../componentes/PreguntasFrecuentes";
export default function Preguntas({ id }) {
  return (
    <React.Fragment>
      <div id={id}  className="title-cart2">
        <div className="title-cart">
          <h1 className="color-white1">PREGUNTAS FRECUENTES</h1>
          <h2 className="color-white1">Así funciona Che Comodin.</h2>
        </div>
        <img
          src={`${imagenes.urlImage}/1_question_mark.png`}
          className="juego-dados"
          alt="carrito"
        />
      </div>

      <div className="preguntas_seccion" id="preguntas-nav">
        <div className="preguntas_items">
          <PreguntasFrecuentes />
        </div>
      </div>

      {/* <div className="centrar">
        <Accordion allowZeroExpanded>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>PREGUNTA</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p className="p">
                Exercitation in fugiat est ut ad ea cupidatat ut in cupidatat
                occaecat ut occaecat consequat est minim minim esse tempor
                laborum consequat esse adipisicing eu reprehenderit enim.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                PREGUNTA PREGUNTA PREGUNTA
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p className="p">
                In ad velit in ex nostrud dolore cupidatat consectetur ea in ut
                nostrud velit in irure cillum tempor laboris sed adipisicing eu
                esse duis nulla non.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </div> */}
    </React.Fragment>
  );
}
