import {
    FETCH_PROJECTS,
    REGISTER_REPRESENTANTE_REQUEST,
    REGISTER_REPRESENTANTE_SUCCESS,
    REGISTER_REPRESENTANTE_FAIL,
    USER_REGISTER_CONTACTO_REQUEST,
    USER_REGISTER_CONTACTO_SUCCESS,
    USER_REGISTER_CONTACTO_FAIL,
  } from "../constants/ProjectType";
  import clienteAxios from '../config/axios';
import swal from "sweetalert";

  
  // export const fetchProjects = () => async (dispatch) => {
  //   const res = await fetch(URL_SERVER + "/projects");
  //   const data = await res.json();
  //   console.log(data.data);
  //   dispatch({
  //     type: FETCH_PROJECTS,
  //     payload: data.data,
  //   });
  // };
  export const fetchProjects = () => async (dispatch) => {
    try {
      const { data } = await clienteAxios.get( "/projects");
      dispatch({ type: FETCH_PROJECTS, payload: data.data });
    } catch (error) {
      dispatch({ payload: error.message });
    }
  };
  
  
  const registerRepresentante = (name, last_name, identification, telephone,email, city) => async (dispatch) => {
    dispatch({ type: REGISTER_REPRESENTANTE_REQUEST, payload: { name:name, last_name:last_name, identification:identification, telephone:telephone,email:email, city:city } });
  
    try {
      const { data } = await clienteAxios.post("/paginas/addRepresentante", { name:name, last_name:last_name, identification:identification, telephone:telephone,email:email, city:city });
      dispatch({ type: REGISTER_REPRESENTANTE_SUCCESS, payload: data.data });
      if (data.status === "success") {

        swal({
          title: "Enviado!",
          text: "Nos comunicáremos pronto contigo",
          dangerMode: true,
          icon: "success",
          button: "ok",
          closeModal: true,
        }).then((willDelete) => {
          if (willDelete) {
            window.location.replace("/");
          }
        });
      } else{
        swal({
          title: "¡Ups!",
          text: data.message,
          icon: "",
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
          }
        });
      }
      // localStorage.setItem('donador', JSON.stringify(data));
      //console.log(data.data)
    } catch (error) {
      swal({
        title: "¡Ups!",
        text: error.message,
        icon: "",
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
        }
      });
      dispatch({ type: REGISTER_REPRESENTANTE_FAIL, payload: error.message });
    }
  }
  
  
  const registerLocal = (name, last_name, identification, heading, name_local, telephone,email, city) => async (dispatch) => {
    dispatch({ type: REGISTER_REPRESENTANTE_REQUEST, payload: {name:name, last_name:last_name, identification:identification, heading:heading, name_local:name_local, telephone:telephone,email:email, city:city} });
  
    try {
      const { data } = await clienteAxios.post("/paginas/addLocal", { name:name, last_name:last_name, identification:identification, heading:heading, name_local:name_local, telephone:telephone,email:email, city:city });
      dispatch({ type: REGISTER_REPRESENTANTE_SUCCESS, payload: data.data });
      if (data.status === "success") {

        swal({
          title: "Enviado!",
          text: "Nos comunicáremos pronto contigo",
          dangerMode: true,
          icon: "success",
          button: "ok",
          closeModal: true,
        }).then((willDelete) => {
          if (willDelete) {
            window.location.replace("/");
          }
        });
      } else{
        swal({
          title: "¡Ups!",
          text: data.message,
          icon: "",
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
          }
        });
      }
     
    } catch (error) {
      swal({
        title: "¡Ups!",
        text: error.message,
        icon: "",
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
        }
      });
      dispatch({ type: REGISTER_REPRESENTANTE_FAIL, payload: error.message });
    }
  }
  const registerInfluencer = (name, last_name, identification,influencer_profile, telephone, email, city) => async (dispatch) => {
    dispatch({ type: REGISTER_REPRESENTANTE_REQUEST, payload: {name:name, last_name:last_name, identification:identification,influencer_profile:influencer_profile, telephone:telephone, email:email, city:city} });
  
    try {
      const { data } = await clienteAxios.post("/paginas/addInfluencer", { name:name, last_name:last_name, identification:identification,influencer_profile:influencer_profile, telephone:telephone, email:email, city:city });
      dispatch({ type: REGISTER_REPRESENTANTE_SUCCESS, payload: data.data });
      if (data.status === "success") {

        swal({
          title: "Enviado!",
          text: "Nos comunicáremos pronto contigo",
          dangerMode: true,
          icon: "success",
          button: "ok",
          closeModal: true,
        }).then((willDelete) => {
          if (willDelete) {
            window.location.replace("/");
          }
        });
      } else{
        swal({
          title: "¡Ups!",
          text: data.message,
          icon: "",
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
          }
        });
      }
   
      
    } catch (error) {
      swal({
        title: "¡Ups!",
        text: error.message,
        icon: "",
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
        }
      });
      dispatch({ type: REGISTER_REPRESENTANTE_FAIL, payload: error.message });
    }
  }
  
  const contacto = (name,last_name, email, message) => async (dispatch) => {
    dispatch({ type: USER_REGISTER_CONTACTO_REQUEST, payload: { name:name,last_name:last_name, email:email, message:message} });
  
    try {
      const { data } = await clienteAxios.post("/paginas/addContacto", { name:name,last_name:last_name, email:email, message:message });
      dispatch({ type: USER_REGISTER_CONTACTO_SUCCESS, payload: data.data });
      if (data.status === "success") {

        swal({
          title: "Gracias por contactarnos!",
          text: "Te responderemos lo antes posible",
          dangerMode: true,
          button: "ok",
          icon: "success",
          closeModal: true,
        }).then((willDelete) => {
          if (willDelete) {
            window.location.replace("/");
          }
        });
      } else{
        swal({
          title: "¡Ups!",
          text: data.message,
          icon: "",
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
          }
        });
      }
   
     
    } catch (error) {
      swal({
        title: "¡Ups!",
        text: error.message,
        icon: "",
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
        }
      });
      dispatch({ type: USER_REGISTER_CONTACTO_FAIL, payload: error.message });
    }
  }
  

  
  export {registerRepresentante,registerLocal,registerInfluencer, contacto};