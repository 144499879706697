import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";
import SolidButton from "../componentes/buttons/SolidButton";
import Card from "react-bootstrap/Card";
import { addToCart } from "../action/CartActions";
import useInitiaState from "../hooks/useInitiaState";
import { Link } from "react-router-dom";
import serverComodin from "../config/serverComodin";
import { priceNivel } from "../componentes/Util";
import { imagenes } from "../config/configuraciones";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const API = serverComodin.baseURL + "/product/st/destacados";

function ProductosP() {
  const [pageNumber, setPageNumber] = useState(1);
  const [productos, setProductos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [items, setItems] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  let nivel = null;
  if (userInfo) {
    nivel = userInfo.nivel;
  }

  const config_server = JSON.parse(localStorage.getItem("configServer"));
  const dispatch = useDispatch();

  const config = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1500,
    autoplaySpeed: 4500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settings = config;

  const fetchProductos = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API}?page=${pageNumber}`);
      const json = await response.json();
      const data = json.data.productos;
      console.log(data)
      
      if (Array.isArray(data) && data.length > 0) {
        setProductos((prevProductos) => [...prevProductos, ...data]);
        setPageNumber((prevPageNumber) => prevPageNumber + 1);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching productos:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSwipe = () => {
    if (!loading && hasMore) {
      fetchProductos();
    }
  };

  useEffect(() => {
    fetchProductos();
  }, []); // Solo se ejecuta al montar el componente



  return (
    <div className="imagen-home-promociones">
      <div className="title-cart2">
        <img
          src={`${imagenes.urlImage}/shopping_basket.png`}
          className="juego-dados"
          alt="carrito"
        />
        <div className="title-cart">
          <h1>PRODUCTOS</h1>
          <h2>¡Todas las jugadas suman puntos!</h2>
        </div>
      </div>
      <br />
      <br />
      <div className="productos">
        {/* <Slider {...settings} onSwipe={handleSwipe}> */}
          {productos.map((product,key) => {
            return (
              <div key={key} className="producto_container">
                <div className="producto_imagen">
                  <img
                    src={`${config_server.urlProducto}/${product.imagen_producto}`}
                    className="products-carousel"
                    alt="Producto, premios y beneficios del club comodín. "
                  />
                </div>
                {/* <h1>dfgdfgdfg</h1> */}
                <h2
                  className="centrar color-black card-title"
                  dangerouslySetInnerHTML={{
                    __html: product.nombre_producto,
                  }}
                />
                <img
                  src={`${imagenes.urlImage}/grupo-559.png`}
                  className="img-estrellas"
                  alt="estrellas"
                />
                {userInfo ? (
                  <div className="price-user1">
                    <div className="centrar nivel-user">{userInfo.nivel}:</div>
                    {priceNivel(
                      product.precio_bronce_producto,
                      product.precio_plata_producto,
                      product.precio_oro_producto,
                      product.precio_platino_producto,
                      userInfo.nivel
                    )}{" "}
                    Pts
                  </div>
                ) : (
                  <h3>
                    Bronce: {product.precio_bronce_producto} Pts
                    <br />
                    Plata: {product.precio_plata_producto} Pts
                    <br />
                    Oro: {product.precio_oro_producto} Pts
                    <br />
                    Platino: {product.precio_platino_producto} Pts
                    <br />
                  </h3>
                )}
                {/* <button className="btn_green">¡Quiero!</button> */}
                <div className="botones-productos">
                  {/* <SolidButton
                    type="agregar-productos-p"
                      onClick={() => dispatch(addToCart(product,nivel))}
                      txt="Agregar al carrito"
                    ></SolidButton> */}
                  {config_server.botonComprarAhora === true ? (
                    <div>
                      {userInfo ? (
                        <Link to="/carrito">
                          <SolidButton
                            type="agregar-productos-p"
                            onClick={() => dispatch(addToCart(product, nivel))}
                            txt="¡Quiero!"
                          ></SolidButton>
                        </Link>
                      ) : (
                        <Link to="/signin">
                          <SolidButton
                            type="agregar-productos-p"
                            onClick={() => dispatch(addToCart(product, nivel))}
                            txt="¡Quiero!"
                          ></SolidButton>
                        </Link>
                      )}
                    </div>
                  ) : (
                    <br />
                  )}
                </div>
              </div>
            );

            // <div className="producto_container">
            //   <div className="producto_imagen">
            //     <img src={productImg} alt="" />
            //   </div>
            //   <h1>{productName}</h1>
            //   <img src={estrellasImg} alt="" className="estrellas" />
            //   <h2>{productPuntos} Puntos</h2>
            //   <button className="btn_green">¡Quiero!</button>
            // </div>;

            return (
              <div className="producto_container" key={product.id_producto}>
                <Card className="">
                  <Card.Body>
                    <div className="producto_imagen">
                      <Link
                        className="link-name"
                        to={`/products/${
                          product.id_producto
                        }/${product.nombre_producto.replace(/ /g, "-")}`}
                      >
                        <img
                          src={`${config_server.urlProducto}/${product.imagen_producto}`}
                          className="products-carousel"
                          alt="Producto, premios y beneficios del club comodín. "
                        />
                      </Link>
                    </div>
                    <Card.Title>
                      <h2
                        className="centrar color-black card-title"
                        dangerouslySetInnerHTML={{
                          __html: product.nombre_producto,
                        }}
                      />
                    </Card.Title>
                    <div className="centrar">
                      <img
                        src={`${imagenes.urlImage}/grupo-559.png`}
                        className="img-estrellas"
                        alt="estrellas"
                      />
                    </div>

                    {userInfo ? (
                      <div className="price-user1">
                        <div className="centrar nivel-user">
                          {userInfo.nivel}:
                        </div>
                        {priceNivel(
                          product.precio_bronce_producto,
                          product.precio_plata_producto,
                          product.precio_oro_producto,
                          product.precio_platino_producto,
                          userInfo.nivel
                        )}{" "}
                        Pts
                      </div>
                    ) : (
                      <Card.Text>
                        Bronce: {product.precio_bronce_producto} Pts
                        <br />
                        Plata: {product.precio_plata_producto} Pts
                        <br />
                        Oro: {product.precio_oro_producto} Pts
                        <br />
                        Platino: {product.precio_platino_producto} Pts
                        <br />
                      </Card.Text>
                    )}
                  </Card.Body>

                  <div className="botones-productos">
                    {/* <SolidButton
                    type="agregar-productos-p"
                      onClick={() => dispatch(addToCart(product,nivel))}
                      txt="Agregar al carrito"
                    ></SolidButton> */}
                    {config_server.botonComprarAhora === true ? (
                      <div>
                        {userInfo ? (
                          <Link to="/carrito">
                            <SolidButton
                              type="agregar-productos-p"
                              onClick={() =>
                                dispatch(addToCart(product, nivel))
                              }
                              txt="¡Quiero!"
                            ></SolidButton>
                          </Link>
                        ) : (
                          <Link to="/signin">
                            <SolidButton
                              type="agregar-productos-p"
                              onClick={() =>
                                dispatch(addToCart(product, nivel))
                              }
                              txt="¡Quiero!"
                            ></SolidButton>
                          </Link>
                        )}
                      </div>
                    ) : (
                      <br />
                    )}
                  </div>
                </Card>
              </div>
            );
          })}
        {/* </Slider> */}
      </div>
      {loading && <p>Loading...</p>}
      {!loading && !hasMore && <p>No hay más productos</p>}
      <div className="botones">
        <Link to="/canjea-tus-puntos">
          <SolidButton txt="Ver todo"></SolidButton>
        </Link>
      </div>
    </div>
  );
}

export default ProductosP;
