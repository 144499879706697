import React, { useState } from "react";
import { useSpring, animated } from "react-spring";

function Preguntas(props) {
  const [open, setOpen] = useState(false);

  let toggleHandler = (e) => {
    setOpen(!open);
  };

  const styles = {
    preguntasTitle: {
      color: open ? "#feda39" : "#fff",
    },
  };

  const openPreguntas = useSpring({
    from: { opacity: "0", maxHeight: "25px" },
    to: { opacity: "1", maxHeight: open ? "400px" : "35px" },
    config: { duration: "300" },
  });

  const iconAnimation = useSpring({
    from: {
      transform: "rotate(0deg)",
      color: "#ffff",
    },
    to: {
      transform: open ? "rotate(180deg)" : "rotate(0deg)",
      color: open ? "#feda39" : "#fff",
    },
    config: { duration: "120" },
  });

  return (
    <animated.div className="accordion__item" style={openPreguntas}>
      <div className="accordion__header" onClick={toggleHandler}>
        <h4 style={styles.preguntasTitle}>{props.title}</h4>
        <animated.i style={iconAnimation} className="iconAnimation">
          <i className="bi bi-chevron-down"></i>
        </animated.i>
      </div>
      <p className="accordion__content">{props.text}</p>
    </animated.div>
  );
}

export default Preguntas;
